<template>
  <div>
    <agent-tab select_page="login" />
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          เข้าสู่ระบบ
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <b-row class="mx-auto mb-1">
            <b-col cols="md-3 col-sm-4">
              <b-form-group>
                  <label class="control-label">รหัสผู้ใช้</label>
                  <input type="text" v-model="result.username" class="form-control" placeholder="รหัสผู้ใช้">
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-4">
              <b-form-group>
                  <label class="control-label">รหัสผ่าน</label>
                  <input type="text" v-model="result.password" class="form-control" placeholder="รหัสผ่าน">
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-4">
              <b-form-group>
                  <label class="control-label">ตำแหน่ง</label>
                  <input type="text" v-model="result.role" class="form-control" placeholder="ตำแหน่ง" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-2">
              <b-button @click="login()" variant="btn-primary" class="btn bg-gradient-dark waves-effect waves-light mt-2"><span><feather-icon icon="LogInIcon" class="font-medium-1 mr-1" /></span> ล็อคอิน </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AgentTab from './agent_tab.vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
    AgentTab,
  },
  data() {
    return {
      PATH: '/agent',
      BASE_URL: process.env.BASE_URL,
      API_POST_URL: 'agent/getagent/' + this.$route.params.id,
      API_POST_LOGIN: 'https://ag.gufabet.com',
      result: {
        username: '',
        password: '',
        role: '',
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.$http.post(this.API_POST_URL).then(res => { 
        if (res.data != null && res.data.result) {
          this.result = res.data
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
    login() {
      let data = {
        username: this.result.username,
        password: this.result.password,
        admin: this.userData.displayName,
        id: this.userData.id,
      }
      this.$http.post(this.API_POST_LOGIN, data).then(res => { 
        if (res.data != null) {
          window.open(res.request.responseURL || this.API_POST_LOGIN, '_blank')
        }
        else this.$router.push(this.PATH)
      }).catch(console.error)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
